import { useEffect, useState } from 'react';

import { DirectionsRun, Inventory, PriorityHigh } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';

import DashboardCard from '../../../components/Dashboard/DashboardCard';
import Projects from './Projects';
import RefineProjectResults from './RefineProjectResults';

import { ROLE_SUPERTAS } from 'constants/users';
import projectService from 'services/projectService';
import { useGetCurrentUserQuery, useGetProjectsQuery, useGetRecruitersQuery } from 'api/api';
import { PROJECT_STATUS } from 'constants/project';

export default function DashboardSummary() {
  const [filters, setFilters] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: '',
    excludeStatus: [PROJECT_STATUS.DRAFT, PROJECT_STATUS.INTAKE, PROJECT_STATUS.ARCHIVED],
    page: '',
    limit: '',
  });
  const [_filterst, _setFilters] = useState(filters);
  const [counters, setCounters] = useState({ activeProjects: 0, urgentProjects: 0, archivedProjects: 0 });

  const { data: projects, isLoading } = useGetProjectsQuery({ ..._filterst });

  const [projectStatus, setProjectStatus] = useState({
    urgent_only: false,
    hide_archived: true,
  });

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: recruiters } = useGetRecruitersQuery();

  const toggleUrgentOnly = (ev) => {
    setProjectStatus({ ...projectStatus, urgent_only: ev.target.checked });
    setFilters((prev) => ({ ...prev, status: ev.target.checked ? PROJECT_STATUS.URGENT : '' }));
    _setFilters((prev) => ({ ...prev, status: ev.target.checked ? PROJECT_STATUS.URGENT : '' }));
  };

  const toggleHideArchived = (ev) => {
    setProjectStatus({ ...projectStatus, hide_archived: ev.target.checked });
    setFilters((prev) => ({
      ...prev,
      excludeStatus: ev.target.checked ? [PROJECT_STATUS.ARCHIVED] : [],
    }));
    _setFilters((prev) => ({
      ...prev,
      excludeStatus: ev.target.checked ? [PROJECT_STATUS.ARCHIVED] : [],
    }));
  };

  const handleFilterProjects = () => {
    _setFilters(filters);
  };

  const handleReset = (filters) => {
    _setFilters(filters);
  };

  useEffect(() => {
    (async () => {
      if (!currentUser) {
        return;
      }

      if (currentUser.role !== ROLE_SUPERTAS) {
        const isRecruiter = recruiters?.find((recruiter) => recruiter.id === currentUser.id);
        if (!isRecruiter) return;

        setFilters((prev) => ({ ...prev, recruiters: [currentUser.id] }));
        _setFilters((prev) => ({ ...prev, recruiters: [currentUser.id] }));
        setCounters({
          activeProjects: currentUser.activeProjects,
          urgentProjects: currentUser.urgentProjects,
          archivedProjects: currentUser.archivedProjects,
        });
      }
    })();
  }, [currentUser, recruiters]);

  useEffect(() => {
    (async () => {
      if (!currentUser) return;

      if (currentUser.role === ROLE_SUPERTAS) {
        try {
          const { data } = await projectService.getAllCounters();
          setCounters({
            activeProjects: data[0].activeProjects,
            urgentProjects: data[0].urgentProjects,
            archivedProjects: data[0].archivedProjects,
          });
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [currentUser]);

  const CardUrgent = () => {
    return (
      <DashboardCard total={counters.urgentProjects} totalSize={90} bgcolor="red-card">
        <Typography>
          <PriorityHigh sx={{ fontSize: 45 }} />
        </Typography>
        <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>URGENT</Typography>
        <Typography sx={{ fontSize: 22 }}>PROJECTS</Typography>
      </DashboardCard>
    );
  };

  const CardActive = () => {
    return (
      <DashboardCard total={counters.activeProjects} totalSize={90} bgcolor="green-card">
        <Typography>
          <DirectionsRun sx={{ fontSize: 45 }} />
        </Typography>
        <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>ACTIVE</Typography>
        <Typography sx={{ fontSize: 22 }}>PROJECTS</Typography>
      </DashboardCard>
    );
  };

  const CardArchived = () => {
    return (
      <DashboardCard total={counters.archivedProjects} totalSize={90} bgcolor="blue-card">
        <Typography>
          <Inventory sx={{ fontSize: 45 }} />
        </Typography>
        <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>ARCHIVED</Typography>
        <Typography sx={{ fontSize: 22 }}>PROJECTS</Typography>
      </DashboardCard>
    );
  };

  return (
    <Box>
      <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
        Welcome <strong>{currentUser?.first_name}!</strong>
      </Typography>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <CardUrgent />
        <CardActive />
        <CardArchived />
      </Stack>
      <Divider sx={{ mt: 10, mb: 8, borderBottomWidth: 2 }} />
      <RefineProjectResults
        {...{
          filters,
          setFilters,
          projectStatus,
          setProjectStatus,
          toggleUrgentOnly,
          toggleHideArchived,
          handleFilterProjects,
          handleReset,
        }}
      />
      <Projects {...{ projects }} isLoading={isLoading} />
    </Box>
  );
}
