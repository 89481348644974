import { connect } from 'react-redux';
import { logout } from 'store/auth';
import { Avatar, Box, Divider, FormLabel } from '@mui/material';
import { styled } from '@mui/system';
import { useTabInterviewState } from 'contexts/TabInterviewContext';
import { useEffect, useState } from 'react';
import candidateService from 'services/candidateService';
import projectService from 'services/projectService';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';
import { capitalizeString } from 'utils/utils';
import ManageCandidateNotes from './Elements/ManageCandidateNotes';
import Title from './Elements/Title';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import ManageCandidateNCS from './Elements/ManageCandidateNCS';
import { POSITIONS, ROLE_ADMIN, ROLE_SUPERTAS } from '@nexxus/constants';

const GridContainer2 = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(min(200px,50%),1fr))',
  gridGap: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '150px 1fr',
  gridGap: theme.spacing(2),
}));

const GridContainer3 = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '112px 1fr',
  gridGap: theme.spacing(2),
  alignItems: 'center',
}));

const RightInterviewTab = ({ setHasChanged, auth }) => {
  const {
    currentCandidateId,
    currentIsDisabled,
    globalDataUpdateDone,
    setProjectDataUpdateDone,
    handleSave,
    fields,
    gradeOptions,
    errors,
  } = useTabInterviewState();

  const [candidateProjects, setCandidateProjects] = useState([]);
  const [project, setProject] = useState('');
  const [mustHaveSkills, setMustHaveSkills] = useState('');
  const [niceToHaveSkills, setToNiceHaveSkills] = useState('');
  const [projectEngineeringInterview, setProjectEngineeringInterview] = useState('');
  const [projectImg, setProjectImg] = useState('');

  // Get All Candidate Code Tests
  useEffect(() => {
    (async () => {
      if (!(currentCandidateId && project)) {
        setMustHaveSkills('');
        setToNiceHaveSkills('');
        setProjectEngineeringInterview('');
        return;
      }
      try {
        const res = await candidateService.getProjectMeta(currentCandidateId, project);
        if (res.status !== 200) return;
        const { data } = res;
        setMustHaveSkills(data.meta.must_have_skills ?? '');
        setToNiceHaveSkills(data.meta.nice_to_have_skills ?? '');
        setProjectEngineeringInterview(data.meta.engineering_interview ?? '');
      } catch (e) {
        console.log(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCandidateId, project]);

  // Update Candidate Grade
  useEffect(() => {
    (async () => {
      setProjectDataUpdateDone(false);
      if (globalDataUpdateDone) {
        if (project) {
          await candidateService.updateProjectMeta(currentCandidateId, project, {
            must_have_skills: mustHaveSkills,
            nice_to_have_skills: niceToHaveSkills,
            engineering_interview: projectEngineeringInterview,
          });
        }
      }
      setProjectDataUpdateDone(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDataUpdateDone]);

  // Get Projects By Candidate

  useEffect(() => {
    (async () => {
      if (currentCandidateId) {
        setProject('');
        const res = await projectService.getActiveProjectsByCandidate(currentCandidateId);
        if (res.status === 200) {
          const { data } = res;
          const projects = data.map((project) => ({
            label:
              project.Client.companyName +
              ' - ' +
              Object.values(POSITIONS).find((p) => p._id === project.position)?.label +
              ' - ' +
              capitalizeString(project.seniority),
            _id: project.id,
            logo: project.Client.logoUrl,
          }));
          setCandidateProjects(projects);
        }
      }
    })();
  }, [currentCandidateId]);

  const handleChange = () => {
    setHasChanged(true);
  };

  return (
    <Box sx={{ mb: 5 }}>
      <Title sx={{ textAlign: { xs: 'left', xl: 'right' } }}>GRADE BASED ON ENGINEERING INTERVIEW</Title>

      <GridContainer sx={{ marginTop: 2, gridTemplateColumns: '100px auto' }}>
        <Box sx={{ justifySelf: 'center' }}>
          <Avatar sx={{ width: 75, height: 75 }} src={projectImg} />
        </Box>
        <GridContainer3>
          <FormLabel sx={{ textAlign: 'right' }}>Select Project</FormLabel>
          <InputAutoCompleteV2
            id="project"
            label=""
            opts={candidateProjects}
            onChangeInput={(e) => {
              const targetVal = e.target.value;
              const projectObj = candidateProjects.find((project) => project._id === targetVal);
              projectObj && setProjectImg(projectObj?.logo ?? '');
              setProject(targetVal);
            }}
            initialValue={project}
          />
        </GridContainer3>
      </GridContainer>
      <GridContainer2 sx={{ marginTop: 3, marginBottom: 3 }}>
        <InputAutoCompleteV2
          id={'project_engineering_interview'}
          label="PROJECT ENG. INTERVIEW"
          onChangeInput={(e) => {
            setProjectEngineeringInterview(e.target.value);
            handleChange();
          }}
          autocomplete={false}
          opts={gradeOptions}
          placeholder={project ? 'Select Grade' : 'No Data'}
          initialValue={projectEngineeringInterview}
          isDisabled={currentIsDisabled || !project}
        />
        <InputAutoCompleteV2
          id={'engineering_interview'}
          label="ENGINEERING INTERVIEW"
          opts={gradeOptions}
          autocomplete={false}
          placeholder="Select Grade"
          initialValue={fields.engineering_interview}
          error={errors.engineering_interview && errors.engineering_interview.txt}
          isDisabled={currentIsDisabled || !project}
          onChangeInput={handleSave}
        />
        <InputAutoCompleteV2
          id={'must_have_skills'}
          label="Must Have Skills"
          onChangeInput={(e) => {
            setMustHaveSkills(e.target.value);
            handleChange();
          }}
          autocomplete={false}
          opts={gradeOptions}
          placeholder={project ? 'Select Grade' : 'No Data'}
          initialValue={mustHaveSkills}
          isDisabled={currentIsDisabled || !project}
        />
        <InputAutoCompleteV2
          id={'nice_to_have_skills'}
          label="Nice to Have Skills"
          onChangeInput={(e) => {
            setToNiceHaveSkills(e.target.value);
            handleChange();
          }}
          autocomplete={false}
          opts={gradeOptions}
          placeholder={project ? 'Select Grade' : 'No Data'}
          initialValue={niceToHaveSkills}
          isDisabled={currentIsDisabled || !project}
        />
      </GridContainer2>
      {project && (
        <>
          <ManageCandidateNotes
            key={project}
            isDisabled={currentIsDisabled || !project}
            scope={CANDIDATE_NOTE_SCOPE.PROJECT}
            projectId={project}
            height={280}
            label="ENGINEERING INTERVIEW NOTES"
          />
          <Divider sx={{ mt: 2, mb: 2 }} />
          {[ROLE_SUPERTAS, ROLE_ADMIN].includes(auth.user?.role) && (
            <ManageCandidateNCS candidateId={currentCandidateId} projectId={project} disabled={currentIsDisabled} />
          )}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightInterviewTab);
