import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import ManageCountryList from './ManagedCountryList';
import CountryAvailabilityForm from './CountryAvailabilityForm';
import { useGetManagedCountriesQuery } from 'api/api';
import CandidateSettingsSkeleton from 'pages/tas/CandidateSettingsSkeleton';

const ManageCountriesContent = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data: managedCountries, refetch: refetchManagedCountries } = useGetManagedCountriesQuery();

  return (
    <CandidateSettingsSkeleton
      title={'Countries in which we cannot hire'}
      subTitle={'Below you will find a list of countries that due to their local laws, their availability of shipping goods, or their capability of having a stable power or internet grid'}
    >
      <Grid container spacing={4} marginTop={3}>
        <Grid item xs={12} md={8}>
          <Typography
            sx={{
              color: '#0242D1',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            Countries
          </Typography>
          <ManageCountryList
            managedCountries={managedCountries}
            onSelectCountry={(country) => setSelectedCountry(country)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            mb={3}
            sx={{
              color: '#0242D1',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            {selectedCountry ? 'Edit Entry' : 'Add Country'}
          </Typography>
          <CountryAvailabilityForm
            country={selectedCountry}
            managedCountries={managedCountries}
            onSubmit={() => {
              setSelectedCountry(null);
              refetchManagedCountries();
            }}
            onCancel={() => setSelectedCountry(null)}
          />
        </Grid>
      </Grid>
    </CandidateSettingsSkeleton>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountriesContent);
