import Joi from 'joi';
import { setValidationMessages } from 'utils/validationMessages';

export const schemaOnboardingForm = Joi.object({
  onboarding_cand_fullname: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_nickname: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_location: Joi.number()
    .integer()
    .messages(setValidationMessages(['number'])),
  onboarding_cand_startdate: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_topskills: Joi.array()
    .items(Joi.number().integer())
    .min(1)
    .messages(setValidationMessages(['array', 'required'])),
  onboarding_cand_source: Joi.number()
    .integer()
    .messages(setValidationMessages(['number'])),
  onboarding_cand_salary: Joi.number()
    .integer()
    .messages(setValidationMessages(['number'])),
  onboarding_cand_payment_schema: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_additional_notes: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_phone: Joi.string()
    .min(11)
    .max(15)
    .messages({
      ...setValidationMessages(['string', 'required']),
      'string.pattern.base': 'E.g. +1(123)123-1234',
    }),
  onboarding_cand_linkedin: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  onboarding_cand_visa_status: Joi.number()
    .integer()
    .messages(setValidationMessages(['number', 'required'])),
  onboarding_cand_visa_type: Joi.number()
    .integer()
    .messages(setValidationMessages(['number', 'required'])),
  onboarding_cand_timezone: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_position_cand_timezone: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_work_hour_start: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_cand_work_hour_end: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_customer_id: Joi.number()
    .integer()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  onboarding_customer_position: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_customer_seniority: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_customer_rate: Joi.number()
    .integer()
    .messages(setValidationMessages(['number', 'required'])),
  onboarding_customer_jd_link: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  onboarding_customer_jd: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
    onboarding_hardware_needs: Joi.number()
    .optional()
    .allow('')
    .messages(setValidationMessages(['number'])),
  onboarding_hardware_notes: Joi.string()
    .optional()
    .allow(''),
    onboarding_hardware_needs_option: Joi.string()
    .optional()
    .allow(''),
  onboarding_customer_webpage: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  onboarding_customer_timezone: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  onboarding_customer_stakeholders: Joi.array().items(
    Joi.object().keys({
      name: Joi.string()
        .required()
        .messages(setValidationMessages(['string', 'required'])),
      email: Joi.string()
        .required()
        .email({ minDomainSegments: 2, tlds: { allow: false } })
        .messages(setValidationMessages(['string', 'required'])),
    })
  ),
  onboarding_cv_file: Joi.string().optional().allow(''),
  onboarding_cv_file_originalName: Joi.string().optional().allow(''),
  onboarding_attachment_file: Joi.string().optional().allow(''),
  onboarding_attachment_file_originalName: Joi.string().optional().allow(''),
});
