/* eslint-disable react/jsx-no-duplicate-props */
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useGetCurrentUserQuery } from 'api/api';
import MainLayout from 'components/MainLayoutRedesign/MainLayout';
import TasMainLayout from 'components/MainLayoutRedesign/TasMainLayout';

import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { useState, useRef, useCallback } from 'react';
import useCustomerNotifications from 'hooks/customer/useCustomerNotifications';
import { setCandidateInfo, setCandidateOpen } from 'store/customer';
import moment from 'moment';
import { EmptyStateDefault, InputCheckbox } from '@nexxus/components';
import ListIcon from '@mui/icons-material/List';
import notificationService from 'services/notificationService';
import { capitalizeString } from '@nexxus/utils';
import Paginator from './Paginator';
import useTasNotifications from 'hooks/tas/useTasNotifications';

const LoadMoreNotifications = () => {
  return (
    <Stack spacing={2} direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ mb: 1, mt: 2 }}>
      <CircularProgress
        thickness={4}
        color="primary"
        sx={{
          width: '30px!important',
          height: '30px!important',
          mr: 2,
        }}
      />
      <Typography sx={{ color: '#5F6368', fontSize: '16px', textAlign: 'left', pl: 1 }}>
        Loading more notifications
      </Typography>
    </Stack>
  );
};

const NotificationCenter = (props) => {
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const theme = useTheme();
  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();
  const callToApi = props.isTasAdmin ? useTasNotifications : useCustomerNotifications;
  const { notifications, handleWidgetClick, notificationsIsLoading, refetchNotifications } = props.isTasAdmin
    ? callToApi()
    : callToApi(currentUser, currentUserIsSuccess, props.setCandidateOpen);
  const [markAll, setMarkAll] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterCenter, setFilterCenter] = useState({ flag: 'all' });

  const calculateTimeNotification = useCallback((dateOlder) => {
    const now = moment();
    const past = moment(dateOlder);

    // Calculating differences
    const minutesPast = now.diff(past, 'minutes');
    const hoursPast = now.diff(past, 'hours');
    const daysPast = now.diff(past, 'days');
    const weeksPast = now.diff(past, 'weeks');

    if (weeksPast > 0) return `${weeksPast} Weeks Ago`;
    else if (daysPast > 0) return `${daysPast} Days Ago`;
    else if (hoursPast > 0) return `${hoursPast} Hours Ago`;
    else return `${minutesPast} Minutes Ago`;
  }, []);

  const toggleNotification = (id) => {
    if (selectedNotifications.includes(id)) {
      setSelectedNotifications(selectedNotifications.filter((notificationId) => id !== notificationId));
    } else {
      setSelectedNotifications([...selectedNotifications, id]);
    }
  };

  const toggleAllRead = (isChecked) => {
    if (!isChecked) {
      setSelectedNotifications([]);
    } else {
      const visbleNotifications = notifications?.notifications
        .filter(filterList)
        .slice(startIndex, endIndex)
        .map((not) => not.id);
      setSelectedNotifications(visbleNotifications);
    }
  };

  const sendToMarkAsRead = async () => {
    await notificationService.updateBulkToggleAsRead({ arrNotification: selectedNotifications, markAsRead: true });
    refetchNotifications();
  };

  const titlePosition = useCallback((position, seniority) => {
    let devTitle = position?.replace('developer', 'dev');
    switch (seniority) {
      case 'senior':
        devTitle = devTitle + ' (SR)';
        break;
      case 'mid-level':
        devTitle = devTitle + ' (ML)';
        break;
      case 'principal':
        devTitle = devTitle + ' (PE)';
        break;
      case 'junior':
        devTitle = devTitle + ' (JR)';
        break;
      default:
        devTitle = devTitle + ' ()';
        break;
    }

    return capitalizeString(devTitle);
  }, []);

  const filterList = (item) => {
    if (filterCenter.flag === 'feedback') {
      return item.type === 'feedback';
    } else if (filterCenter.flag === 'pipeline') {
      return item.type === 'pipeline';
    }
    return item;
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const getNotificationList = (notifiactionList = []) =>
    notificationsIsLoading ? (
      <LoadMoreNotifications />
    ) : notifiactionList?.length > 0 ? (
      notifiactionList
        .slice(startIndex, endIndex)
        ?.filter(filterList)
        .map((notificationItem) => (
          <Stack
            key={notificationItem.id}
            direction="row"
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{ width: '100%', mb: 1, opacity: notificationItem.markAsRead ? 0.5 : 1 }}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          >
            <Button
              key={notificationItem.id}
              sx={{
                textTransform: 'inherit',
                width: { xs: 'calc(100% - 60px)', md: '100%' },
                justifyContent: 'flex-start',
              }}
              onClick={(event) => {
                handleWidgetClick({
                  notificationId: notificationItem.id,
                  markAsRead: notificationItem.markAsRead,
                  target: event.currentTarget,
                  CandidateId: notificationItem?.CandidateId,
                  ProjectId: notificationItem.ProjectId,
                  type: notificationItem.type,
                  currentStatus: notificationItem.meta.statusPipeline,
                });
              }}
            >
              <Stack
                key={notificationItem.id}
                direction="row"
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{ width: '100%', fontWeight: 'bold', flexWrap: { md: 'nowrap' } }}
              >
                <Avatar sx={{ width: 40, height: 40, mr: 1 }} src={notificationItem?.Candidate?.avatar_url} />
                <Stack direction={'column'} sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      color: '#656565',
                      fontSize: 14,
                      fontWeight: 400,
                      '&>span': { fontWeight: 700 },
                      textAlign: 'left',
                      [theme.breakpoints.up('sm')]: {
                        pr: '10px',
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: notificationItem.meta.message }}
                  ></Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: '#656565',
                      fontWeight: '800',
                      textAlign: 'left',
                      fontStyle: 'italic',
                      mt: '2px',
                      fontSize: 12,
                      [theme.breakpoints.up('md')]: {
                        '& > span': { display: 'none' },
                      },
                      [theme.breakpoints.down('md')]: {
                        '& > span': { display: 'block', float: 'right' },
                      },
                    }}
                  >
                    <b>
                      {props.isTasAdmin ? notificationItem?.Project?.Client?.companyName : ''}
                    </b>
                    {` - ${titlePosition(notificationItem.Project.position, notificationItem.Project.seniority)}`}
                    <span>{calculateTimeNotification(notificationItem.createdAt)}</span>
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    width: { xs: '100%', md: '100px' },
                    flexShrink: 0,
                    color: '#656565',
                    fontWeight: 800,
                    textAlign: { xs: 'left', md: 'inherit' },
                    [theme.breakpoints.down('md')]: {
                      pl: '48px',
                      display: 'none',
                    },
                  }}
                >
                  {calculateTimeNotification(notificationItem.createdAt)}
                </Box>
              </Stack>
            </Button>
            <Box sx={{ width: '60px', flexShrink: 0, textAlign: 'center' }}>
              <InputCheckbox
                element={'isStaff'}
                sxCheckbox={{ width: '20px', height: '20px' }}
                isChecked={selectedNotifications.includes(notificationItem.id)}
                onChecked={() => {
                  toggleNotification(notificationItem.id);
                }}
              />
            </Box>
          </Stack>
        ))
    ) : (
      <Stack direction={'column'} justifyContent="center" alignItems="center" sx={{ pt: theme.spacing(3), width: '100%' }}>
        <EmptyStateDefault sx={{ width: 100, height: 100 }}></EmptyStateDefault>
        <Typography variant="h6" sx={{ fontWeight: 700, color: theme.palette.primary.main, pt: theme.spacing(2) }}>
          No Notifications Yet
        </Typography>
      </Stack>
    );

  const Container = props.isTasAdmin ? TasMainLayout : MainLayout;

  return (
    <Container {...props} current="projects" avatarTopBar={currentUser?.Client?.logoUrl ?? ''} feedbackMissed={''}>
      <Stack spacing={2}>
        <Typography
          color="#0242D1"
          textTransform="capitalize"
          fontSize="24px"
          my="20px!important"
          sx={{ lineHeight: '28px', fontWeight: '500' }}
        >
          Notifications Center
        </Typography>
      </Stack>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems={'stretch'} sx={{ minHeight: '675px' }}>
        <Grid item xs={12} md={3} lg={2.5} xl={2.5}>
          <Box
            sx={{
              background: '#fff',
              p: { xs: 2, md: 4 },
              borderRadius: '5px',
            }}
          >
            <Typography
              sx={{
                color: '#5F6368',
                fontSize: '20px',
                textAlign: 'left',
                pl: 1,
                pb: 2,
                [theme.breakpoints.down('md')]: { pt: 2 },
                [theme.breakpoints.down('sm')]: { pt: 1 },
              }}
            >
              All Notifications
            </Typography>
            <Stack spacing={1} direction={{ xs: 'column', sm: 'row', md: 'column' }}>
              <Button
                onClick={() => {
                  setFilterCenter({ flag: 'all' });
                  setMarkAll(null);
                }}
                sx={{ textTransform: 'inherit', fontSize: '16px', justifyContent: 'flex-start', textAlign: 'left' }}
              >
                <ListIcon sx={{ mr: 1 }} />
                All
              </Button>
              <Button
                onClick={() => {
                  setFilterCenter({ flag: 'feedback' });
                  setMarkAll(null);
                }}
                sx={{ textTransform: 'inherit', fontSize: '16px', justifyContent: 'flex-start', textAlign: 'left' }}
              >
                <ModeCommentOutlinedIcon sx={{ mr: 1 }} />
                Feedback
              </Button>
              <Button
                onClick={() => {
                  setFilterCenter({ flag: 'pipeline' });
                  setMarkAll(null);
                }}
                sx={{ textTransform: 'inherit', fontSize: '16px', justifyContent: 'flex-start' }}
              >
                <WorkOutlineOutlinedIcon sx={{ mr: 1 }} />
                Positions
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={9.5} xl={9.5}>
          <Box
            sx={{ background: 'white', borderRadius: '5px', minHeight: '400px', px: { xs: 2, md: 4 }, py: { xs: 2, md: 4 } }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%', mt: '0px!important', pb: '20px' }}
            >
              <Typography variant="h6" color="primary" sx={{ color: '#656565', fontSize: 16, mt: '0px' }}>
                Latest Notifications
              </Typography>
              <FormControlLabel
                control={
                  <InputCheckbox
                    element={'isStaff'}
                    sxCheckbox={{ width: '20px', height: '20px', marginRight: '1rem' }}
                    isChecked={markAll}
                    onChecked={(e, isChecked) => toggleAllRead(isChecked)}
                  />
                }
                label={
                  selectedNotifications.length ? (
                    <Button onClick={sendToMarkAsRead} disabled={!selectedNotifications.length}>
                      Mark as read
                    </Button>
                  ) : (
                    <></>
                  )
                }
                sx={{
                  mr: '20px',
                  color: '#656565',
                  fontSize: 16,
                  ml: 'auto',
                  flexDirection: 'row-reverse',
                  '& > span.MuiCheckbox-root': { pl: 2 },
                }}
              />
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ width: '100%', maxHeight: '90vh', overflow: 'auto', mt: '0px!important', mb: '2em' }}
            >
              <Divider sx={{ borderColor: '#DADADA', mt: '0px!important', width: '100%', mb: '16px!important' }} />
              {getNotificationList(notifications?.notifications || [])}
            </Stack>
            <Paginator
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalRows={notifications?.notifications?.filter(filterList)?.length}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    setCandidateOpen: (payload) => {
      dispatch(setCandidateOpen(payload));
    },
    setCandidateInfo: () => {
      dispatch(setCandidateInfo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
