import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { Box, Typography } from '@mui/material';

export const dataGridStyles = {
  '&': {
    padding: '0 !important',
    margin: '0 !important',
    border: 'none !important',
  },
  '& .MuiDataGrid-main': {
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#656565 !important',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0', // color for odd rows
  },
};

const CandidateSettingsSkeleton = ({ title, subTitle, children }) => {
  return (
    <Box p={3}>
      {(subTitle || title) && (
        <Box sx={{ backgroundColor: '#B0D1FD', borderRadius: '10px', padding: '10px' }}>
          {title && (
            <Typography
              variant="h2"
              mb={1}
              sx={{
                color: '#1841A3',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
              }}
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              variant="h3"
              mb={4}
              sx={{
                color: '#1841A3',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '19px',
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSettingsSkeleton);
