import { Box, Link as LinkMaterial, Stack } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState } from 'react';

const ModalVideoViewer = ({ file, onClose = () => {} }) => {
  const [open, setOpen] = useState(false);

  const onCloseModal = async (event) => {
    event?.preventDefault();
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (file !== '') setOpen(true);
  }, [file]);

  return (
    <ModalFeedback
      variant="general"
      headerText="Video Viewer"
      titleText=""
      open={open}
      onCancel={onCloseModal}
      onClose={onCloseModal}
      modalWidth={1200}
      disableBtnConfirm={true}
      btnCancelText="Close"
    >
      <Box sx={{ p: '0 0 20px' }}>
        <LinkMaterial
          component="a"
          href={file}
          sx={{
            color: 'nexxusBlueExtraLight.main',
            fontWeight: 'bold',
          }}
          target="_blank"
        >
          DOWNLOAD
        </LinkMaterial>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Stack sx={{ width: '60%' }}>
          <video controls>
            <source src={file} />
          </video>
        </Stack>
      </Box>
    </ModalFeedback>
  );
};

export default ModalVideoViewer;
