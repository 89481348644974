import { Edit as EditIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { Grid, IconButton, Pagination, Stack, CircularProgress, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { PdfIcon } from 'assets/icons';
import NoDataOverlay from 'components/NoDataOverlay';
import { publish, subscribe, unsubscribe } from 'events.js';
import useCandidateFilter from 'hooks/useCandidateFilter';
import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import CandidateCard from 'components/Candidates/CardidateCard/CandidateCard';
import ModalPdfViewer from 'components/Generic/Modal/ModalPdfViewer';
import { SkeletonCard } from '@nexxus/components';
import useSignedS3Url from 'hooks/useSignedS3Url';
import { useGetManagedCountriesQuery } from 'api/api';

const CandidateResultTable = ({ auth, only_blacklisted, only_staff }) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(0);
  const [filterParam, setFilterParam] = useState('createdAt')
  const [order, setOrder] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [fileViewer, setFileViewer] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const { getSignedDownloadUrl } = useSignedS3Url();
  const { data: locationData } = useGetManagedCountriesQuery();

  const {
    handleFilterCandidates,
    handleFilterPagination,
    featuredCandidates,
    candidates,
    candidatesIsLoading,
    canddiatesIsSuccess,
    isFetching
  } = useCandidateFilter(only_blacklisted, only_staff);

  useEffect(() => {
    subscribe('CandidateSearchFilterUpdate', (event) => handleFilterCandidates(event?.detail));

    return () => {
      unsubscribe('CandidateSearchFilterUpdate');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    publish('CandidateSearchFeaturedCandidates', featuredCandidates);
  }, [featuredCandidates]);

  useEffect(() => {
    handleFilterPagination({
      page,
      limit: pageSize,
      orderBy: [filterParam],
      order: order ? 'DESC' : 'ASC',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, order]);

  useEffect(() => {
    if (canddiatesIsSuccess) {
      const newPageCount = Math.ceil(parseInt(candidates[0]?.total_count || 0) / pageSize);
      setPageCount(newPageCount);
    }
  }, [canddiatesIsSuccess, candidates, pageSize]);

  useEffect(() => {
    if (canddiatesIsSuccess && candidates) {
      return setRowCount(parseInt(candidates[0]?.total_count) ?? 0);
    }
    setRowCount(0);
  }, [canddiatesIsSuccess, candidates]);

  const getResultText = useCallback(() => {
    if (candidatesIsLoading) {
      return <CircularProgress sx={{ width: '1em!important', height: '1em!important' }} />;
    }
    return candidates.length === 0 ? '0' : `(${candidates[0]?.total_count})`;
  }, [candidatesIsLoading, candidates]);

  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      editable: false,
      flex: 3,
      sortable: false,
    },
    {
      field: 'developer_title',
      headerName: 'Position',
      editable: false,
      flex: 2,
      sortable: false,
    },
    /*{
      field: 'developer_seniority',
      headerName: 'Seniority',
      editable: false,
      flex: 1,
      sortable: false,
    },*/
    {
      field: 'candidateScore',
      headerName: 'Score',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => Math.round(row.candidateScore),
    },
    /*{
      field: 'CandidateSkills',
      headerName: 'Skills',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <CandidatePopover label="VIEW ALL" candidate={params.row} />;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'timezone',
      headerName: 'Time Zone',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      sortable: false,
    },*/
    {
      field: 'createdAt',
      headerName: 'Created',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => moment(row.createdAt).format('MM/DD/YYYY'),
    },
    {
      field: 'updatedAt',
      headerName: 'Last Activity',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => moment(row.updatedAt).format('MM/DD/YYYY'),
    },
    {
      field: 'edit',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'right',
      editable: false,
      sortable: false,
      width: 140,

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={async () => {
                const signedUrl = await getSignedDownloadUrl(params.row.cv_file_url);
                setFileViewer(signedUrl);
              }}
              disabled={!params.row.cv_file_url}
              data-cy="LoadPdf"
            >
              <PdfIcon color={!params.row.cv_file_url ? theme.palette.grey.light : 'nexxusBlack'} />
            </IconButton>
            {auth.user.role === 'superTas' ? (
              <>
                <IconButton component={Link} to={`/tas/candidates/${params.row.id}/edit`}>
                  <EditIcon color={'nexxusBlack'} />
                </IconButton>
                <IconButton component={Link} to={`/tas/candidates/${params.row.id}`}>
                  <VisibilityIcon color={'nexxusBlack'} />
                </IconButton>
              </>
            ) : (
              <IconButton component={Link} to={`/tas/candidates/${params.row.id}`}>
                <VisibilityIcon color={'nexxusBlack'} />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel) => {
    if (sortModel?.length) {
      setFilterParam(sortModel[0].field)
    } else {
      setFilterParam('createdAt')
    }
    setOrder((prev) => !prev);
  }, []);

  return (
    <>
      {!only_staff && (
        <DataGrid
          sx={{ mt: 4 }}
          headerHeight={50}
          autoHeight
          paginationMode="server"
          rowCount={rowCount}
          rows={candidates || []}
          columns={columns}
          rowsPerPageOptions={[5, 12, 20, 50, 100]}
          disableSelectionOnClick
          disableColumnMenu
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            if (newPageSize !== pageSize) {
              setPageSize(newPageSize);
              setPage(0);
            }
          }}
          page={page}
          pagination
          loading={isFetching}
          components={{
            ...theme?.components?.MuiDataGrid?.defaultProps?.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay title="No Data" />,
              NoResultsOverlay: () => <NoDataOverlay title="No Data" />,
            },
          }}
          onPageChange={(newPage) => {
            if (newPage !== page) setPage(newPage);
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      )}

      {only_staff && (
        <>
          <Typography variant="subtitle" my={4} mb={6} gap="1em" textAlign="center">
            Matching Results {getResultText()}
          </Typography>

          <Grid container spacing={2} rowSpacing={6}>
            {candidatesIsLoading &&
              [...new Array(3)].map((_e, j) => {
                return (
                  <Grid item xs={12} smd={6} lg3={4} xl2={3} key={`${uuidv4()}`}>
                    <SkeletonCard variant="candidate" sx={{ margin: 'auto', mt: 2 }} />
                  </Grid>
                );
              })}
            {!candidatesIsLoading &&
              candidates?.map((candidate) => (
                <Grid item xs={12} smd={6} lg3={4} xl2={3} key={candidate.id}>
                  <CandidateCard candidate={candidate} locations={locationData} />
                  <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<VisibilityIcon />}
                      href={`/tas/candidates/${candidate.id}`}
                    >
                      View Candidate
                    </Button>
                  </Stack>
                </Grid>
              ))}
          </Grid>
          <Stack mt={6} direction="row" justifyContent="center">
            {!candidatesIsLoading && (
              <Pagination
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                  setPage(newPage - 1);
                }}
              />
            )}
          </Stack>
        </>
      )}
      <ModalPdfViewer fileViewer={fileViewer} onClose={() => setFileViewer('')} />
    </>
  );
};

export default CandidateResultTable;
