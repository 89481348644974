const { api, useGetTasNotificationsQuery } = require('api/api');
const { useCallback, useState } = require('react');
const { useDispatch } = require('react-redux');
const { default: NotificationService } = require('services/notificationService');
import { CANDIDATEPROJECT_STATUS, GROUP_PROJECT_STATUS_REJECTED } from '@nexxus/constants';
import { useNavigate, createSearchParams } from 'react-router-dom';

const useTasNotifications = (setCandidateOpen) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [notifictionCount, setNotificationsCount] = useState(0);
  const {
    data: notifications,
    isLoading: notificationsIsLoading,
    refetch: refetchNotifications,
  } = useGetTasNotificationsQuery();

  const searchCacheNotification = useCallback(
    (notificationId) => {
      const props = { typeNotification: null, index: null };
      Object.keys(notifications).forEach((key) => {
        if (Array.isArray(notifications[key])) {
          const result = notifications[key].find((obj) => obj.id === notificationId);
          const resultIndex = notifications[key].findIndex((obj) => obj.id === notificationId);
          if (result) {
            props.typeNotification = key;
            props.index = resultIndex;
          }
        }
      });
      return props;
    },
    [notifications]
  );

  const toggleMarkAsRead =
    async (notificationId, newMarkAsRead, typeNotification, index) => {
      await NotificationService.updateMarkAsRead(notificationId, { markAsRead: newMarkAsRead });
      dispatch(
        api.util.updateQueryData('getTasNotifications', undefined, (notifications) => {
          notifications[typeNotification][index].markAsRead = newMarkAsRead;
          if (newMarkAsRead) {
            notifications.totalReaded = notifications.totalReaded + 1;
            notifications.totalNotRead = notifications.totalNotRead - 1;
          } else {
            notifications.totalReaded = notifications.totalReaded - 1;
            notifications.totalNotRead = notifications.totalNotRead + 1;
          }
          return notifications;
        })
      );
    };

  const handleWidgetClick = useCallback(
    async ({ notificationId, markAsRead, target, CandidateId, ProjectId, type, currentStatus }) => {
      const { typeNotification, index } = searchCacheNotification(notificationId);
      if (!markAsRead && typeNotification) {
        await toggleMarkAsRead(notificationId, true, typeNotification, index);
      }

      if (type === 'feedback') {
        history({
          pathname: `/tas/candidates/${CandidateId}`,
        });
      } else if (type === 'pipeline') {
        const newStatus = [...GROUP_PROJECT_STATUS_REJECTED].includes(CANDIDATEPROJECT_STATUS[currentStatus])
          ? 'REJECTED'
          : currentStatus;
        const params = { ProjectId: ProjectId, Tab: newStatus };
        history({
          pathname: `/tas/projects/${ProjectId}/view`,
          search: `?${createSearchParams(params)}`,
        });
      } else {
        history({
          pathname: `/tas/projects/${ProjectId}/edit`,
        });
      }
    },
    [searchCacheNotification, toggleMarkAsRead, setCandidateOpen, history]
  );

  return {
    notifications,
    refetchNotifications,
    handleWidgetClick,
    toggleMarkAsRead,
    searchCacheNotification,
    notificationsIsLoading,
    notifictionCount,
    setNotificationsCount,
  };
};

export default useTasNotifications;
