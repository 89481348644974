import { ExtendsValidationHandler } from '@nexxus/components';
import { schemaOnboardingForm } from './OnboardingSchema';

export const OnboardingInitialStateRequest = {
  onboarding_cand_fullname: '',
  onboarding_cand_nickname: '', // test
  onboarding_cand_location: '',
  onboarding_cand_startdate: '', // test
  onboarding_cand_topskills: [], // test
  onboarding_cand_source: '', // test
  onboarding_cand_salary: '', // test
  onboarding_cand_payment_schema: '',
  onboarding_cand_additional_notes: '', // test
  onboarding_cand_email: '',
  onboarding_cand_phone: '',
  onboarding_cand_linkedin: '',
  onboarding_cand_visa_status: '',
  onboarding_cand_visa_type: '',
  onboarding_cand_timezone: '',
  onboarding_position_cand_timezone: '',
  onboarding_cand_work_hour_start: '', // test
  onboarding_cand_work_hour_end: '', // test
  onboarding_customer_id: '',
  onboarding_customer_position: '',
  onboarding_customer_seniority: '',
  onboarding_customer_rate: '', // test
  onboarding_customer_jd_link: '',
  onboarding_customer_webpage: '',
  onboarding_customer_timezone: '', // test
  onboarding_customer_stakeholders: [{ name: '', email: '' }], // test
  onboarding_cv_file: '',
  onboarding_cv_file_originalName: '',
  onboarding_attachment_file: '',
  onboarding_attachment_file_originalName: '',
};

// export const OnboardingInitialStateRequest = {
//   onboarding_cand_fullname: '',
//   onboarding_cand_nickname: 'lorem ipsum dolor', // test
//   onboarding_cand_location: '',
//   onboarding_cand_startdate: '12-05-2023', // test
//   onboarding_cand_topskills: [39, 40], // test
//   onboarding_cand_source: 1, // test
//   onboarding_cand_salary: 500, // test
//   onboarding_cand_payment_schema: '',
//   onboarding_cand_additional_notes: 'lorem ipsum dolor sit ament', // test
//   onboarding_cand_email: '',
//   onboarding_cand_phone: '',
//   onboarding_cand_linkedin: '',
//   onboarding_cand_visa_status: '',
//   onboarding_cand_visa_type: '',
//   onboarding_cand_timezone: '',
//   onboarding_cand_work_hour_start: '13', // test
//   onboarding_cand_work_hour_end: '07', // test
//   onboarding_customer_id: '',
//   onboarding_customer_position: '',
//   onboarding_customer_seniority: '',
//   onboarding_customer_rate: 500, // test
//   onboarding_customer_jd_link: '',
//   onboarding_customer_webpage: '',
//   onboarding_customer_timezone: 'act', // test
//   onboarding_customer_stakeholders: [{ name: 'jane doe', email: 'jane@doe.com' }], // test
//   onboarding_cv_file: '',
//   onboarding_cv_file_originalName: '',
//   onboarding_attachment_file: '',
//   onboarding_attachment_file_originalName: '',
// };

export default function OnboardingFormFields(data = OnboardingInitialStateRequest) {
  const {
    alertMessage,
    setAlertMessage,
    alertFormNotification, //   handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    handleSubmit,
    control,
    register,
  } = ExtendsValidationHandler({ schemaData: schemaOnboardingForm, defaultValues: data });

  return {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    control,
    alertFormNotification,
    alertMessage,
    setAlertMessage,
    register,
  };
}
