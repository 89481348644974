import TagsDataGrid from './TagsDataGrid';
import { useTags } from 'hooks/useTagsRefactored';
import CreateTagForm from './CreateTagForm';
import { toast } from 'react-toastify';
import { Button, Grid, Stack, Typography } from '@mui/material';
import CandidateSettingsSkeleton from '../CandidateSettingsSkeleton';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { useEffect, useState } from 'react';

const TagsPageContent = () => {
  const { tags, refetch } = useTags();
  const [filteredTags, setFilteredTags] = useState(tags);
  const [selectedTags, setSelectedTags] = useState('');

  useEffect(() => {
    setFilteredTags([...tags]);
  }, [JSON.stringify(tags)])

  const onSuccess = (data) => {
    console.log(data);
    refetch();
    toast.success('Tag created');
  };

  const onError = (error) => {
    console.log(error);
    toast.error('Error creating tag');
  };

  const onDelete = () => {
    refetch();
  };

  const handleTagSearch = (e) => {
    if (e.target.value) {
      setSelectedTags(e.target.value);
      setFilteredTags(tags.filter((t) => t.id === e.target.value));
    }
  };

  return (
    <CandidateSettingsSkeleton>
      <Grid container spacing={4} marginTop={3}>
        <Grid item xs={12} md={8}>
          <Grid item xs={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={5}>
            <span style={{ color: '#0242D1', fontWeight: 'bold' }}>Tag</span>
            <Stack spacing={2} direction={'row'} width={'80%'} justifyContent={'flex-end !important'}>
              <InputAutoCompleteV2
                id={'tgsDb'}
                size={'normal'}
                label=""
                placeholder="Search tag"
                error={''}
                initialValue={selectedTags}
                opts={tags?.map((tag) => ({ _id: tag.id, label: tag.name })) ?? []}
                onChangeInput={handleTagSearch}
                isDisabled={false}
                multiple={false}
                disableClearable
                sxContainer={{ maxWidth: '350px' }}
              />
              <Button
                variant="contained"
                color="warning"
                size="small"
                disabled={!selectedTags}
                onClick={() => {
                  setSelectedTags('');
                  setFilteredTags(tags);
                }}
                sx={{}}
              >
                Clear Filter
              </Button>
            </Stack>
          </Grid>
          <TagsDataGrid onDelete={onDelete} tags={filteredTags} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            mb={3}
            sx={{
              color: '#0242D1',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            {'Add tag'}
          </Typography>
          <CreateTagForm onSuccess={onSuccess} onError={onError} />
        </Grid>
      </Grid>
    </CandidateSettingsSkeleton>
  );
};

export default TagsPageContent;
