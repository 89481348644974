import { useEffect, useState } from 'react';
import { CLIENTSTATUS } from 'utils/staticData';
import { useGetCustomersQuery, useGetProjectsQuery } from 'api/api';
import { PROJECT_STATUS } from '@nexxus/constants';

const useManageProjects = () => {
  const [client, setClient] = useState({});
  const [filters, setFilters] = useState({
    client: '',
    hideArchiveCustomers: true,
    hideArchivePosition: true,
    recruiters: [],
    position: '',
    technology: '',
    status: [],
    excludeStatus: [PROJECT_STATUS.DRAFT],
    page: '',
    limit: '',
  });
  const [countProjects, setCountProjects] = useState({ URGENT: 0, ACTIVE: 0, ARCHIVED: 0 });
  const { data: projects, isLoading: projectsIsLoading } = useGetProjectsQuery(filters);

  useEffect(() => {
    (async () => {
      if (filters.client === '') setClient({});
      else {
        const clientSelected = customers.find((c) => c.id === filters.client);
        setClient(clientSelected);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  useEffect(() => {
    (async () => {
      const counts = { URGENT: 0, ACTIVE: 0, ARCHIVED: 0 };
      if (projects) {
        projects.forEach((pj) => {
          if (pj.status === PROJECT_STATUS.ACTIVE) counts.ACTIVE++;
          if (pj.status === PROJECT_STATUS.URGENT) counts.URGENT++;
          if (pj.status === PROJECT_STATUS.ARCHIVED) counts.ARCHIVED++;
        });
      }
      setCountProjects((prev) => ({ ...prev, ACTIVE: counts.ACTIVE, URGENT: counts.URGENT, ARCHIVED: counts.ARCHIVED }));
    })();
  }, [projects]);

  const totalSize = 100;

  const { data: customers } = useGetCustomersQuery(
    filters.hideArchiveCustomers ? { excludeStatus: [CLIENTSTATUS.ARCHIVED] } : {}
  );

  return {
    client,
    filters,
    setFilters,
    customers,
    totalSize,
    countProjects,
    projects,
    projectsIsLoading,
  };
};

export default useManageProjects;
