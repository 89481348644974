import { Grid, Button, FormLabel, FormControl, FormHelperText } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useAddTagMutation } from 'api/api';

import { OutlinedInput } from '@nexxus/components';

const CreateTagForm = ({ onSuccess, onError }) => {
  const schema = Joi.object({
    name: Joi.string().trim().min(1).required().label('Tag Name'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const [addTag] = useAddTagMutation();

  const onSubmit = async (data) => {
    console.log(data);

    addTag(data)
      .unwrap()
      .then((response) => {
        console.log(response);
        onSuccess(response.data);
        reset();
      })
      .catch((error) => {
        console.log(error);
        onError(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <OutlinedInput fullWidth sx={{ color: '#000' }} {...field} />}
            />
            <FormHelperText>{errors.name && errors.name.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>&nbsp;</FormLabel>
            <Button type="submit" variant="contained" sx={{ width: '100% ' }} size="large" color="primary">
              Add New Tag
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateTagForm;
