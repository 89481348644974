import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useGetCandidatesQuery } from 'api/api';

const useCandidateFilter = (only_blacklisted = false, only_staff = false) => {
  const [filters, setFilters] = useState({ page: 0, limit: 10, orderBy: ['createdAt'], order: 'DESC' });
  const [pagination, setPagination] = useState({});
  const [featuredCandidates, setFeaturedCandidates] = useState([]);
  const {
    data: candidates,
    isLoading: candidatesIsLoading,
    error: candidatesError,
    isSuccess: canddiatesIsSuccess,
    isFetching,
    refetch,
  } = useGetCandidatesQuery({ ...filters, ...pagination, only_blacklisted, only_staff });

  const handleFilterCandidates = (handle_filters) => {
    setFilters(handle_filters);
  };

  const handleFilterPagination = (handle_pagination) => {
    setPagination({ ...handle_pagination });
  };

  useEffect(() => {
    candidates && setFeaturedCandidates(candidates?.slice(0, 4));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(candidates)]);

  useEffect(() => {
    if (!_.isEmpty(pagination)) refetch();
  }, [pagination, refetch]);

  return {
    filters,
    handleFilterCandidates,
    handleFilterPagination,
    candidatesIsLoading,
    isFetching,
    featuredCandidates,
    candidatesError,
    canddiatesIsSuccess,
    candidates,
    refetch,
  };
};

export default useCandidateFilter;
