import { Button, Grid } from '@mui/material';
import { ExtendsValidationHandler } from '@nexxus/components';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import Joi from 'joi';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import adminService from 'services/administrationService';
import { hookFormTrimValues } from 'utils/utils';
import { setValidationMessages } from 'utils/validationMessages';

const customSkillValidation = async (value) => {
  const { data } = await adminService.getSkill(value);
  if (data)
    throw Object.assign(new Error('Skill already exists'), {
      details: [
        {
          context: {
            label: 'Skill Name',
            key: 'skillName',
          },
          code: 422,
          type: 'any.invalid',
          path: ['skillName'],
          message: 'Skill already exists',
        },
      ],
    });
  return true;
};

const AddSkillModal = ({ onHandleSaved, skillSelected }) => {
  const formRef = useRef();
  const { errors, setValue, getValues, reset, handleSubmit } = ExtendsValidationHandler({
    schemaData: Joi.object({
      skillName: Joi.string()
        .required()
        .external(customSkillValidation, 'custom validation')
        .messages(setValidationMessages(['string', 'required'])),
    }),
    defaultValues: { skillName: '' },
  });

  useMemo(() => {
    reset((prev) => ({
      ...prev,
      skillName: !_.isEmpty(skillSelected) && skillSelected?.name !== '' ? skillSelected.name : '',
    }));
  }, [reset, skillSelected]);

  const handleOnChangeInput = (evt) => {
    if (evt.target.value !== getValues(evt.target.name)) {
      setValue(evt.target.name, evt.target.value);
    }
  };

  const onSubmit = useCallback(async () => {
    const trimmedFields = hookFormTrimValues(getValues());
    try {
      if (!_.isEmpty(skillSelected) && skillSelected.name !== '') {
        await adminService.updateSkill(skillSelected.id, {
          ...trimmedFields,
        });
      } else {
        await adminService.createSkill(trimmedFields);
      }
      reset({ skillName: '' });
    } catch (e) {
      console.log('error: ', e?.response?.data);
      let err = '';
      if (typeof e?.response?.data?.message === 'string') {
        err = e.response.data.message;
      } else {
        err = 'Something went wrong saving skill!';
      }
      toast.error(err);
      return;
    }

    toast.success('Skill saved succesfully');
    onHandleSaved();
  }, [getValues, onHandleSaved, skillSelected]);

  return (
    <Grid container spacing={2} gap={'20px'} marginTop={5} component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <InputTextOutlinedV2
        id="skillName"
        label="Skill Name"
        placeholder=""
        onChangeInput={handleOnChangeInput}
        initialValue={getValues('skillName')}
        error={errors.skillName?.message}
      />
      <Button type="submit" variant="contained" sx={{ width: '100% ' }} size="large" color="primary">
        { skillSelected?.name ? 'SAVE' : 'Add new' }
      </Button>
    </Grid>
  );
};

AddSkillModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onHandleSaved: PropTypes.func,
};

export default AddSkillModal;
