import { Box, Button, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { useGetSkillsQuery } from 'api/api';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import AddSkillModal from './addSkillModal';
import EditIcon from '@mui/icons-material/Edit';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import AdministrationService from 'services/administrationService';
import { toast } from 'react-toastify';
import CandidateSettingsSkeleton, { dataGridStyles } from '../CandidateSettingsSkeleton';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const AdminSkills = (props) => {
  const [pageSize, setPageSize] = useState(15);
  const initialFilters = {
    skill: '',
    includeInactive: false,
  };
  const [filters, setFilters] = useState(initialFilters);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { data: skills, isLoading, refetch } = useGetSkillsQuery();
  const [skillsDB, setSkillsDB] = useState([]);
  const [filteredSkillsDB, setFilteredSkillsDB] = useState([]);

  const [skillSelected, setSkillSelected] = useState({});

  useMemo(() => {
    setSkillsDB(skills);
    setFilteredSkillsDB(filters.skill ? skills.filter((sk) => sk.id === filters.skill) : skills);
  }, [skills]);

  const handleSearch = (skillId) => {
    setFilteredSkillsDB(skillsDB.filter((sk) => sk.id === skillId));
  };

  const handleEditSkill = useCallback((row, type) => {
    setSkillSelected(row);
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      editable: false,
      width: 140,
      renderCell: ({ row }) => (
        <Box display={'flex'} alignItems={'center'}>
          {!row.isActive && <Typography color={'#979797'}>OFF</Typography>}
          <Switch defaultChecked={row.isActive} onChange={(_, checked) => onHandleDeleteUser(row)} />
          {row.isActive && <Typography color={'#004DD3'}>ON</Typography>}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditSkill(row, 'edit')} size="small">
            <EditIcon sx={{ color: '#004DD3' }} />
          </IconButton>
        );
      },
    },
  ];

  const handleSaved = useCallback(() => {
    setSkillSelected({ name: '' });
    refetch();
  }, [refetch]);

  const handleSkillSearch = async (e) => {
    if (e.target.value !== filters.skill) {
      setFilters((prev) => ({ ...prev, skill: e.target.value }));
      handleSearch(e.target.value);
    }
  };

  const onHandleDeleteUser = useCallback(
    async (skill) => {
      try {
        await AdministrationService.updateSkill(skill.id, {
          isActive: !skill.isActive,
        });
        toast.success('Skill saved succesfully');
        handleSaved();
      } catch (error) {
        toast.error('Something went wrong, please try again!');
      }
    },
    [skillSelected, handleSaved]
  );

  return (
    <CandidateSettingsSkeleton>
      <Grid container spacing={4} marginTop={3}>
        <Grid item xs={12} md={8}>
          <Grid container sx={{ justifyContent: 'center!important' }}>
            <Grid item xs={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <span style={{ color: '#0242D1', fontWeight: 'bold' }}>Skill</span>
              <Stack spacing={2} direction={'row'} width={'80%'} justifyContent={'flex-end !important'}>
                <InputAutoCompleteV2
                  id={'skillsDB'}
                  size={'normal'}
                  label=""
                  placeholder="Search Skill"
                  error={''}
                  initialValue={filters.skill}
                  opts={skills?.map((sk) => ({ _id: sk.id, label: sk.name })) ?? []}
                  onChangeInput={handleSkillSearch}
                  isDisabled={false}
                  multiple={false}
                  disableClearable
                  sxContainer={{ maxWidth: '350px' }}
                />
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={!filters.skill}
                  onClick={() => {
                    setFilters(initialFilters);
                    setFilteredSkillsDB(skillsDB);
                    setSkillSelected({});
                  }}
                  sx={{}}
                >
                  Clear Filter
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <DataGrid
            sx={dataGridStyles}
            headerHeight={50}
            rows={filteredSkillsDB || []}
            loading={isLoading}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            components={{
              ...{
                NoRowsOverlay: () => <NoDataOverlay />,
                NoResultsOverlay: () => <NoDataOverlay />,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            mb={3}
            sx={{
              color: '#0242D1',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            {'Add skill'}
          </Typography>
          <AddSkillModal onHandleSaved={handleSaved} skillSelected={skillSelected} />
        </Grid>
      </Grid>
      <ModalFeedback
        variant="warning"
        open={openDeleteModal}
        headerText={`${skillSelected?.isActive ? 'Disable' : 'Enable'} Current Skill`}
        titleText={`Do you want to ${skillSelected?.isActive ? 'disable' : 'enable'} this skill?`}
        onCancel={() => {
          setOpenDeleteModal(false);
          setSkillSelected({ name: '' });
        }}
        onAccept={onHandleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSkillSelected({ name: '' });
        }}
      />
    </CandidateSettingsSkeleton>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSkills);
