import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Stack,
  SvgIcon,
  Switch,
  Typography,
  styled,
} from '@mui/material';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { CANDIDATE_STATUS, CANDIDATE_VISA_STATUS_OPTS } from 'constants/candidate';
import { ROLE_SUPERTAS, ROLE_TAS } from 'constants/users';
import _ from 'lodash';
import CandidateHeaderForm from 'pages/tas/candidateForm/candidateHeaderForm';
import ModalTags from 'pages/tas/candidates/modalTags';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputProfileImage from 'components/FormElement/InputProfileImage';
import candidateService from 'services/candidateService';
import { loginExpire } from 'store/auth';
import 'react-phone-input-2/lib/bootstrap.css';
import { candidateCvWorkExperienceInitialState } from 'pages/tas/candidateForm/candidateFields';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { availabilityOptions, employmentType, salaryCurrency, seniority, timezoneOptsCand } from 'utils/staticData';
import { POSITIONS, UPLOAD_LOCATIONS } from '@nexxus/constants';
import { hookFormTrimValues, setNumberFormatted } from 'utils/utils';
import { schemaTAS } from 'validations/candidateRegister';
import CandidateFormAutofilled from '../candidates/ExtraDataCandidate/CandidateFormAutofilled';
import useCvParser from 'hooks/useCvParser';
import { AccordionWrapper } from 'pages/common/AccordionWrapper';
import FileUploader from '../FileUploader';
import { useGetCatalogPhonesCountriesQuery, useGetTagsQuery, useGetManagedCountriesQuery } from 'api/api';
import { InputPhone } from '@nexxus/components';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import CandidateFields, { candidateInitialState } from './candidateFields';
import useSignedS3Url from '../../../hooks/useSignedS3Url';
import InputDatePicker from 'components/FormElement/InputDatePicker';
import InputFileDragDrop from 'components/FormElement/InputFileDragDrop';
import { useTheme } from '@emotion/react';
import { CircularProgressMUI } from 'stories/playground/mui/CircularProgress.stories';

const CustomBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }) => ({
  width: width ?? 'auto',
  paddingLeft: '0!important',
  paddingRight: '0!important',
  height: '36px',
  borderWidth: '2px',
  fontWeight: '700',
  fontSize: '16px',
  borderRadius: '3px',
  borderColor: '#0242D1',
}));

const CandidateForm = ({ editing, setEditing, auth, setIsCandidateActive, setHasChanged, skillsOptions }) => {
  const theme = useTheme();
  const { CandidateId } = useParams();
  const { data: catalogPhoneCountries } = useGetCatalogPhonesCountriesQuery();
  const { data: tags } = useGetTagsQuery();
  const [notFound, setNotFound] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesVisa, setFilesVisa] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [logoUrlPreview, setLogoUrlPreview] = useState('');
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [initialTags, setInitialTags] = useState([]);
  const [resetTags, setResetTags] = useState(false);
  const location = useLocation();
  const [isEditPage, setIsEditPage] = useState(false);
  const [discard, setDiscard] = useState(false);
  const [headerText, setHeaderText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [showEditBottomBtn, setShowEditBottomBtn] = useState(false);
  const [interviewInputError, setInterviewInputError] = useState(false);
  const { setFileType, setFileCv, cvData, errorParser } = useCvParser();
  const [progressStatus, setProgressStatus] = useState('stopped');
  const [openModalUploader, setOpenModalUploader] = useState(false);
  const [cvFormIsExpanded, setCvFormIsExpanded] = useState(false);
  const candidateFields = CandidateFields(candidateInitialState);
  const { handleFileUpload } = useSignedS3Url();
  const [candidateCountryId, setCandidateCountryId] = useState(null);

  useMemo(() => {
    setHeaderText('Confirm New Candidate');
    setTitleText('Do you want to add a new candidate?');

    if (isEditPage) {
      setHeaderText('Edit Candidate');
      setTitleText('Do you want to edit this candidate?');
    }
  }, [isEditPage]);

  useEffect(() => {
    if (progressStatus === 'stopped') setOpenModalUploader(false);
  }, [progressStatus]);

  useEffect(() => {
    if (discard) {
      setHeaderText('Discard Changes');
      setTitleText('Do you want to discard changes?');
      setOpen(true);
    }
  }, [discard]);

  useMemo(() => {
    const pathname = location.pathname;
    const urlContainsEditText = pathname.includes('edit');
    if (urlContainsEditText || editMode) {
      setIsEditPage(true);
    }
  }, [editMode, location]);

  const onCloseModal = (event) => {
    event?.preventDefault();
    setOpen(false);
    setDiscard(false);
  };

  const onAccept = () => {
    setHasChanged(false);
    if (discard) {
      discardChanges();
      setDiscard(false);
      return;
    }
    setSubmitting(true);
    setOpen(false);
  };

  let navigate = useNavigate();

  const {
    initialStateRequest,
    fields,
    handleSave,
    errors,
    handleSubmit,
    validSubmit,
    initialState,
    setValues,
    resetForm,
    setForm,
    setErrors,
    setInitialStateRequest,
    alertFormNotification,
  } = candidateFields;

  const { data: locationData } = useGetManagedCountriesQuery(
    {
      isHireAvailable: true,
      include: candidateCountryId,
    },
    {
      skip: CandidateId && !candidateCountryId && !fields.first_name,
    }
  );

  const locationOptions = useMemo(() => {
    return locationData?.map((location) => ({
      _id: location.id,
      label: location.name,
    }));
  }, [locationData]);

  const onTagsChangeInput = (items) => {
    const arrItems = _.map(items, (item) => item.id);
    const objInput = { target: { name: 'tags', value: arrItems } };
    handleSave(objInput);
  };

  const populateCandidateDetails = (payload) => {
    candidateService.getCandidateDetails(CandidateId).then(({ data }) => {
      const formValues = {
        ...payload,
        tags: data?.Tags?.map((tag) => tag?.id || ''),
        skillsCV: data?.CandidateResumeSkills?.map((skill) => ({ value: skill.name, validated: true })) || [],
        languagesCV: data?.CandidateResume?.languagesCV || [],
        industry_experienceCV:
          data?.CandidateIndustryExperiences?.map((i) => ({ option: i.IndustryExperienceId, years: Number(i.years) })) || [],
        workExperienceCV: data?.CandidateResume?.workExperienceCV ?? [...fields.workExperienceCV],
        yearsOfExperienceCV: data?.CandidateResume?.yearsOfExperienceCV || '',
        positionCV: data?.CandidateResume?.positionCV || '',
        birthdayCV: data?.CandidateResume?.birthdayCV,
      };

      setInitialStateRequest(formValues);
      setForm(formValues);
      setInitialTags(data.Tags);
    });
  };
  //init Hook
  useEffect(() => {
    (async () => {
      if (!!CandidateId && CandidateId !== 'create') {
        const { data } = await candidateService.getCandidate(CandidateId);
        if (!candidateCountryId) setCandidateCountryId(data?.LocationCountryId);
        if (!data) return setNotFound(true);
        const getDataValue = (dataReference) => data[dataReference] ?? '';
        const getDataValueNested = (dataReference, nestedProp) => data?.[dataReference]?.[nestedProp] ?? '';
        const newData = {
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: getDataValue('phone_number'),
          email: data.email,
          company_email: getDataValue('company_email'),
          status: data.status,
          blacklisted: data.blacklisted,
          linkedin: getDataValue('linkedin'),
          availability: getDataValue('availability'),
          availability_interview: getDataValue('availability_interview'),
          developer_seniority: getDataValue('developer_seniority'),
          developer_title: getDataValue('developer_title'),
          salary_currency: getDataValue('salary_currency'),
          salary_expectation: data.salary_expectation,
          employment_type: getDataValue('employment_type'),
          github_profile: getDataValue('github_profile'),
          portfolio_link: getDataValue('portfolio_link'),
          candidate_result_link: getDataValue('candidate_result_link'),
          candidate_code_score: getDataValue('candidate_code_score'),
          LocationCountryId: getDataValue('LocationCountryId'),
          timezone: getDataValue('timezone'),
          english_proficiency: getDataValue('english_proficiency'),
          info_additional: getDataValue('info_additional'),
          avatar_url: getDataValue('avatar_url'),
          rate_per_hour: getDataValue('rate_per_hour'),
          rate_customer: getDataValue('rate_customer'),
          staff: data.staff,
          //resume info
          resumeRequired: validateNotEmptyGroupFields(fields, [
            'birthdayCV',
            'yearsOfExperienceCV',
            'positionCV',
            'certificationsCV',
            'skillsCV',
          ]),
          birthdayCV: getDataValueNested('CandidateResume', 'birthdayCV'),
          yearsOfExperienceCV: getDataValueNested('CandidateResume', 'yearsOfExperienceCV'),
          positionCV: getDataValueNested('CandidateResume', 'positionCV'),
          certificationsCV: getDataValueNested('CandidateResume', 'certificationsCV'),
          certificationsCV_valid: true,
          languagesCV_valid: true,
          visa_issue_date: getDataValueNested('visa', 'visa_issue_date'),
          visa_expiration_date: getDataValueNested('visa', 'visa_expiration_date'),
          visa_type: getDataValueNested('visa', 'visa_type'),
          visa_type_other: getDataValueNested('visa', 'visa_type_other'),
          visa_nationality: getDataValueNested('visa', 'visa_nationality'),
          visa_status: getDataValueNested('visa', 'visa_status'),
          visa_file: getDataValueNested('visa', 'visa_file'),
          visa_file_originalName: getDataValueNested('visa', 'visa_file_originalName'),
          visa_required: false,
          meta_uploads: getDataValue('meta_uploads'),
        };
        const payload = { ...initialState, ...newData };
        await Promise.all([setInitialStateRequest(payload), setForm(payload), setErrors({ ...initialState })]);
        populateCandidateDetails(payload);
        return 0;
      }
      if (![ROLE_SUPERTAS, ROLE_TAS].includes(auth.user.role) === true) navigate('/403');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CandidateId]);

  const setDataValueResume = (field, newValue) => {
    return initialStateRequest?.[field] === '' ? newValue : initialStateRequest?.[field];
  };

  // cv uploaded, processed and return parsed data
  useEffect(() => {
    (async () => {
      try {
        if (progressStatus !== 'stopped') {
          setProgressStatus('success');
          const {
            first_name,
            last_name,
            phone_number,
            email,
            linkedin,
            github_profile,
            avatar_url,
            birthday,
            position,
            languages,
            certifications,
            workExperience,
            yearsOfExperience,
            skills,
            tempCV,
          } = cvData;
          const dataResumeParsed = {
            languagesCV: languages?.map((el) => el.toLowerCase()),
            languagesCV_valid: false,
            skillsCV: skills?.map((skill) => ({ value: skill.name, validated: false })) || [],
            certificationsCV: certifications,
            certificationsCV_valid: false,
            tempCV,
            workExperienceCV:
              workExperience.length > 0
                ? workExperience?.map((we) => ({
                    we_isValid: false,
                    we_startDate: we.dates.startDate,
                    we_endDate: we.dates.endDate,
                    we_position: we.jobTitle,
                    we_location: we?.location?.formatted || '',
                    we_company: we.organization,
                    we_description: we.jobDescription,
                  }))
                : [{ ...candidateCvWorkExperienceInitialState }],
          };
          if (!CandidateId) {
            dataResumeParsed.first_name = first_name;
            dataResumeParsed.last_name = last_name;
            dataResumeParsed.phone_number = phone_number;
            dataResumeParsed.email = email;
            dataResumeParsed.linkedin = linkedin;
            dataResumeParsed.github_profile = github_profile;
            dataResumeParsed.github_profile = github_profile;
            dataResumeParsed.avatar_url = avatar_url;
          }

          dataResumeParsed.birthdayCV = setDataValueResume('birthdayCV', birthday);
          dataResumeParsed.positionCV = setDataValueResume('positionCV', position);
          dataResumeParsed.yearsOfExperienceCV = setDataValueResume('yearsOfExperienceCV', yearsOfExperience);

          setErrors({});
          setForm({ ...fields, ...dataResumeParsed, resumeRequired: true });
          setHasChanged(true);
          setCvFormIsExpanded(true);
        }
      } catch (e) {
        setProgressStatus('failed');
      } finally {
        setTimeout(() => {
          setProgressStatus('stopped');
        }, 2000);
      }
    })();
  }, [cvData]);

  useEffect(() => {
    if (errorParser !== '') {
      let msg = '';
      if (errorParser.response?.data?.err_desc) msg = errorParser.response.data.err_desc.message;
      else if (errorParser.message) msg = errorParser.message;
      toast.error('An error ocurred during parsing resume: ' + msg);
      setProgressStatus('stopped');
    }
  }, [errorParser]);

  useEffect(() => {
    (async () => {
      const newData = hookFormTrimValues(fields);
      setValues(newData);
      if (!submitting) return;
      setSubmitting(true);
      try {
        if (files.length) {
          const s3File = await handleFileUpload({
            file: files[0],
            contentType: files[0]?.type ?? 'image/png',
            path: UPLOAD_LOCATIONS.CANDIDATE_ASSETS_PUBLIC,
          });

          console.log('s3File %o', s3File);

          if (s3File) {
            newData.avatar_url = s3File.object_url;
          }
        }

        if (filesVisa.length) {
          const s3FileVisa = await handleFileUpload({
            file: filesVisa[0],
            contentType: filesVisa[0]?.type ?? 'image/png',
            path: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
          });

          if (s3FileVisa) {
            newData.visa_file = s3FileVisa.object_url;
            newData.visa_file_originalName = s3FileVisa.originalName;
            setValues({ ...fields, visa_file: s3FileVisa.object_url, visa_file_originalName: s3FileVisa.originalName });
          }
        }

        if (newData.candidate_code_score === '') newData.candidate_code_score = 0;
        if (newData.rate_customer === '') newData.rate_customer = 0;
        if (newData.rate_per_hour === '') newData.rate_per_hour = 0;
        delete newData.meta_uploads;

        if (!newData.LocationCountryId) delete newData.LocationCountryId;

        if (!!CandidateId && CandidateId !== 'create') {
          const { data: candidateUpdated } = await candidateService.patchCandidate(newData, CandidateId);
          newData.tempCV = '';
          await Promise.all([
            setInitialTags(candidateUpdated.Tags),
            toast.success('Candidate saved successfully!'),
            setIsCandidateActive(!newData.blacklisted),
            setValues({ ...initialState, ...newData }),
            setInitialStateRequest({ ...initialState, ...newData }),
            setFiles([]),
            setHasChanged(false),
            setEditing(true),
          ]);
          return navigate(`/tas/candidates/${CandidateId}/view`);
        }

        const { data: candidateSaved } = await candidateService.registerCandidate(newData);
        toast.success('Candidate saved successfully!');
        resetForm();
        return navigate(`/tas/candidates/${candidateSaved.id}/view`);
      } catch (error) {
        let err = '';
        if (typeof error?.response?.data?.message === 'string') {
          err = error.response.data.message;
          return toast.error(err);
        }
        return toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    })();
  }, [validSubmit]);

  const validateNotEmptyGroupFields = (dataFields, fieldsEvaluate) => {
    return (
      fieldsEvaluate?.some((el) => {
        return ['number', 'string'].includes(typeof dataFields[el]) ? dataFields[el] !== '' : dataFields?.[el]?.length > 0;
      }) ?? false
    );
  };

  useMemo(() => {
    if (errors?.availability_interview?.response) {
      setInterviewInputError(true);
    }
    setInterviewInputError(false);
  }, [errors]);

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      resumeRequired: validateNotEmptyGroupFields(fields, [
        'birthdayCV',
        'yearsOfExperienceCV',
        'positionCV',
        'certificationsCV',
        'skillsCV',
      ]),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields.birthdayCV,
    fields.yearsOfExperienceCV,
    fields.positionCV,
    fields.certificationsCV,
    fields.skillsCV,
    setValues,
  ]);

  const handleDrop = (fileAvatar) => {
    if (fileAvatar.length > 1 || !fileAvatar[0].name) return;
    setFiles(fileAvatar);
    setLogoUrlPreview(URL.createObjectURL(fileAvatar[0]));
  };

  const discardChanges = async () => {
    const initial = _.cloneDeep(initialStateRequest);
    await Promise.all([
      setErrors({}),
      setLogoUrlPreview(initial['avatar_url']),
      setFiles([]),
      setResetTags(true),
      setValues(CandidateId ? initial : initialState),
      setHasChanged(false),
    ]);
  };

  const setAvatar = () => {
    return logoUrlPreview || fields?.avatar_url || '';
  };

  const NoDataOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
          NOT FOUND
        </Typography>
        <Typography variant="h6" color="nexxusGrayLight.main">
          Please go to create new candidate
        </Typography>
      </Stack>
    );
  };

  const handleOnChangeInputSelect = useCallback(
    (evt) => {
      if (evt.target.value !== fields[evt.target.name]) {
        setHasChanged(true);
        handleSave(evt);
      }
    },
    [fields, handleSave, setHasChanged]
  );

  const handleOnChange = useCallback(
    (value) => {
      handleOnChangeInputSelect({ target: { name: 'phone_number', value } });
    },
    [handleOnChangeInputSelect]
  );

  const handleOnChangeAccordion = (expanded) => {
    setCvFormIsExpanded((prev) => !prev);
  };

  const openFileDialog = () => {
    setProgressStatus('initial');
    setOpenModalUploader(true);
  };

  const handleDiscardChanges = () => {
    if (!_.isEqual(fields, initialStateRequest) || !_.isEqual(logoUrlPreview, initialStateRequest.avatar_url))
      setDiscard(true);
  };

  const visaFileHandler = useCallback(
    (visaFile) => {
      if (visaFile.length > 0) {
        setFilesVisa(visaFile);
        handleOnChangeInputSelect({ target: { name: 'visa_file', value: visaFile[0].name } });
      } else {
        setFilesVisa([]);
        handleOnChangeInputSelect({ target: { name: 'visa_file', value: '' } });
      }
    },
    [handleOnChangeInputSelect]
  );

  return (
    <>
      {notFound && (
        <Box sx={{ marginY: 20 }}>
          <NoDataOverlay />
        </Box>
      )}
      {!notFound && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              '@media(max-width:560px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box>
              <CandidateHeaderForm
                onSetEditMode={setEditMode}
                showEditBottomBtn={setShowEditBottomBtn}
                editing={editing}
                first_name={fields.first_name}
                last_name={fields.last_name}
                onSetEditing={setEditing}
              >
                <InputProfileImage
                  onHandleDrop={handleDrop}
                  avatar={setAvatar()}
                  name={`${fields.first_name} ${fields.last_name}`}
                  accept={['image/jpeg', 'image/png', 'image/gif']}
                  onHandleDelete={(e) => {
                    setHasChanged(true);
                    setLogoUrlPreview('');
                    setFiles([]);
                    setValues({ ...fields, avatar_url: '' });
                  }}
                  isDisabled={editing}
                />
              </CandidateHeaderForm>
            </Box>

            {!editing && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomBtn onClick={openFileDialog} variant="outlined" component="label" width="220px">
                  Upload Resume
                </CustomBtn>
              </Box>
            )}

            <FileUploader
              setFileType={setFileType}
              setFile={setFileCv}
              openModalUploader={openModalUploader}
              setOpenModalUploader={setOpenModalUploader}
              progressStatus={progressStatus}
              setProgressStatus={setProgressStatus}
            />

            <Stack alignItems="flex-end">
              <ModalTags
                editing={editing}
                tags={tags}
                initialValue={initialTags}
                onHandleTags={(items) => {
                  setHasChanged(true);
                  setResetTags(false);
                  onTagsChangeInput(items);
                }}
                error={errors.tags}
                reset={resetTags}
                setResetTags={setResetTags}
              ></ModalTags>

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      const obj = { target: { name: 'blacklisted', value: e.target.checked } };
                      handleOnChangeInputSelect(obj);
                    }}
                    checked={fields.blacklisted}
                    disabled={editing}
                  />
                }
                label="Blacklisted"
                data-cy="Blacklisted"
                labelPlacement="start"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      const obj = { target: { name: 'staff', value: e.target.checked } };
                      handleOnChangeInputSelect(obj);
                    }}
                    checked={fields.staff}
                    disabled={editing}
                  />
                }
                label="Staff"
                labelPlacement="start"
                data-cy="Staff"
              />
            </Stack>
          </Stack>
          <Divider sx={{ marginY: 2 }} />
          {/* Candidate Info Form */}
          <Grid container spacing={2} component="form" onSubmit={(e) => handleSubmit(e, schemaTAS)}>
            <ModalFeedback
              variant="info"
              headerText={headerText}
              titleText={titleText}
              open={open}
              onCancel={onCloseModal}
              onClose={onCloseModal}
              onAccept={onAccept}
            />
            {CandidateId && (
              <Grid item md={12}>
                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" maxWidth={400}>
                  <FormLabel sx={{ minWidth: 130 }}>Candidate Status:</FormLabel>
                  <InputAutoCompleteV2
                    id={'status'}
                    size={'normal'}
                    label=""
                    opts={[
                      { _id: CANDIDATE_STATUS.ACTIVE, label: 'ACTIVE' },
                      { _id: CANDIDATE_STATUS.ARCHIVED, label: 'ARCHIVED' },
                    ]}
                    error={errors.status?.txt}
                    initialValue={fields.status}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              {alertFormNotification('invalid')}
            </Grid>
            <Grid item xs={12}>
              <Grid container mt={2}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  BASIC CANDIDATE INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* first name  */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  <InputTextOutlinedV2
                    id={'first_name'}
                    size={'normal'}
                    label="FIRST NAME"
                    error={errors.first_name?.txt}
                    initialValue={fields.first_name}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* last name  */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  <InputTextOutlinedV2
                    id={'last_name'}
                    size={'normal'}
                    label="LAST NAME"
                    error={errors.last_name?.txt}
                    initialValue={fields.last_name}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* phone number */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  {catalogPhoneCountries && (
                    <InputPhone
                      id="phone_number"
                      defaultCountry={'mx'}
                      initialValue={fields.phone_number}
                      // @ts-ignore
                      error={errors?.phone_number?.txt}
                      isDisabled={editing}
                      arrOpts={catalogPhoneCountries?.map((pc) => pc.short_name) || ['us', 'mx']}
                      handleOnChange={handleOnChange}
                    />
                  )}
                </Grid>
                {/* email */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  <InputTextOutlinedV2
                    id={'email'}
                    size={'normal'}
                    type="email"
                    label="EMAIL"
                    error={errors.email?.txt}
                    initialValue={fields.email}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* company_email */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  <InputTextOutlinedV2
                    id={'company_email'}
                    size={'normal'}
                    type="email"
                    label="COMPANY EMAIL"
                    error={errors.company_email?.txt}
                    initialValue={fields.company_email}
                    onChangeInput={handleOnChangeInputSelect}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                  />
                </Grid>

                {/* location */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  {!CandidateId || (CandidateId && fields.first_name) ? (
                    <InputAutoCompleteV2
                      id="LocationCountryId"
                      size={'normal'}
                      autocomplete
                      label="LOCATION"
                      initialValue={fields?.LocationCountryId}
                      error={errors.LocationCountryId?.txt}
                      opts={locationOptions ?? []}
                      onChangeInput={handleOnChangeInputSelect}
                      isDisabled={editing}
                    />
                  ) : null}
                </Grid>

                {/* timezone */}
                <Grid item xs={12} md={6} lg={4} lg3={3}>
                  <InputAutoCompleteV2
                    id="timezone"
                    size={'normal'}
                    label="TIMEZONE"
                    initialValue={fields.timezone}
                    error={errors.timezone?.txt}
                    opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container mt={2}>
                  <Typography variant="h7" sx={{ fontWeight: '700' }}>
                    VISA
                  </Typography>
                </Grid>
                <Grid container spacing={2} mt={0.5}>
                  <Grid item xs={12} md={6} lg={8} xl={9} container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputDatePicker
                        id={'visa_issue_date'}
                        label="Issue Date"
                        onChange={(value) => {
                          handleOnChangeInputSelect({ target: { name: 'visa_issue_date', value: value } });
                        }}
                        initialValue={fields?.visa_issue_date}
                        error={errors.visa_issue_date?.txt}
                        isDisabled={editing}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputDatePicker
                        id={'visa_expiration_date'}
                        label="Expiration Date"
                        placeholder=""
                        onChange={(value) =>
                          handleOnChangeInputSelect({ target: { name: 'visa_expiration_date', value: value } })
                        }
                        initialValue={fields?.visa_expiration_date}
                        error={errors.visa_expiration_date?.txt}
                        isDisabled={editing}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputAutoCompleteV2
                        id="visa_nationality"
                        size={'normal'}
                        label="Nationality"
                        initialValue={fields.visa_nationality}
                        error={errors.visa_nationality?.txt}
                        opts={locationOptions?.map((item) => ({ _id: item._id, label: item.label })) || []}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputAutoCompleteV2
                        id="visa_status"
                        size={'normal'}
                        label="Status"
                        initialValue={fields.visa_status}
                        error={errors.visa_status?.txt}
                        opts={CANDIDATE_VISA_STATUS_OPTS}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <InputAutoCompleteV2
                        id="visa_type"
                        size={'normal'}
                        label="TYPE"
                        initialValue={fields.visa_type}
                        error={errors.visa_type?.txt}
                        opts={[
                          { _id: 1, label: 'B1/B2' },
                          { _id: 2, label: 'Other' },
                        ]}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ display: fields?.visa_type === 2 ? 'block' : 'none' }}>
                      <InputTextOutlinedV2
                        id={'visa_type_other'}
                        size={'normal'}
                        label="OTHER TYPE"
                        error={errors.visa_type_other?.txt}
                        initialValue={fields.visa_type_other}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3} container>
                    <FormControl fullWidth sx={{ textAlign: 'left' }} disabled={editing}>
                      <FormLabel error={!!errors.visa_file && errors?.visa_file !== ''} htmlFor={'visa_file'} sx={{}}>
                        Image VISA
                      </FormLabel>

                      <InputFileDragDrop
                        body={'UPLOAD VISA FRONT SIDE ONLY'}
                        caption={'(.pdf, .jpg, .jpeg, .png)'}
                        iconUpload={
                          <FileUploadOutlinedIcon
                            sx={{ width: '40px', height: '40px' }}
                            htmlColor={editing ? 'rgb(2, 66, 209, .5)' : theme.palette.primary.main}
                          />
                        }
                        id={'visa_file'}
                        mimeTypes={['application/pdf', 'image/jpeg', 'image/png']}
                        onFileHandler={visaFileHandler}
                        sxAreaDragDrop={{
                          pointerEvents: 'all!important',
                          [theme.breakpoints.down('md')]: { minHeight: '140px' },
                        }}
                        urlDownload={fields?.visa_file}
                        isDisabled={editing}
                        error={errors?.visa_file?.txt}
                        signDownload={true}
                        settingsDownload={{
                          url: fields?.visa_file ?? '',
                          originalName: fields?.visa_file_originalName ?? '',
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={6}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  CUSTOMER INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* rate for customer  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'rate_customer'}
                    size={'normal'}
                    type={editing ? 'text' : 'number'}
                    label="RATE FOR CUSTOMER"
                    error={errors.rate_customer?.txt}
                    initialValue={setNumberFormatted(fields.rate_customer, editing)}
                    onChangeInput={(e) => (editing ? '' : handleOnChangeInputSelect(e))}
                    isDisabled={editing}
                    onlyNumbers={true}
                    onWheel={(e) => {
                      // Prevent the input value change
                      e.target.blur();
                      e.stopPropagation();
                    }}
                  />
                </Grid>
                {/* rate for customer  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'rate_per_hour'}
                    size={'normal'}
                    type={editing ? 'text' : 'number'}
                    label={`RATE PER HOUR`}
                    error={errors.rate_per_hour?.txt}
                    initialValue={setNumberFormatted(fields.rate_per_hour, editing)}
                    onChangeInput={(e) => (editing ? '' : handleOnChangeInputSelect(e))}
                    isDisabled={editing}
                    onlyNumbers={true}
                    onWheel={(e) => {
                      // Prevent the input value change
                      e.target.blur();
                      e.stopPropagation();
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={6}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  JOB CANDIDATE INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* availability to interview  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputAutoCompleteV2
                    id={'availability'}
                    size={'normal'}
                    label="AVAILABILITY TO JOIN"
                    opts={availabilityOptions}
                    error={errors.availability?.txt}
                    initialValue={fields.availability}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* seniority */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputAutoCompleteV2
                    id={'developer_seniority'}
                    size={'normal'}
                    label="SENIORITY"
                    autocomplete
                    opts={seniority}
                    error={errors.developer_seniority?.txt}
                    initialValue={fields.developer_seniority}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* developer title */}
                <Grid item xs={12} md={6} lg={6}>
                  <InputAutoCompleteV2
                    id={'developer_title'}
                    size={'normal'}
                    label="POSITION"
                    error={errors.developer_title?.txt}
                    initialValue={fields.developer_title}
                    opts={Object.values(POSITIONS)}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* salary expectation */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'salary_expectation'}
                    size={'normal'}
                    type={editing ? 'text' : 'number'}
                    label="Expected Monthly Salary"
                    error={errors.salary_expectation?.txt}
                    initialValue={setNumberFormatted(fields.salary_expectation, editing)}
                    onChangeInput={(e) => (editing ? '' : handleOnChangeInputSelect(e))}
                    isDisabled={editing}
                    onlyNumbers={true}
                    onWheel={(e) => {
                      // Prevent the input value change
                      e.target.blur();
                      e.stopPropagation();
                    }}
                  />
                </Grid>
                {/* currency */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputAutoCompleteV2
                    id={'salary_currency'}
                    size={'normal'}
                    label="CURRENCY"
                    error={errors.salary_currency?.txt}
                    initialValue={fields.salary_currency}
                    opts={_.orderBy(salaryCurrency, [(currency) => currency.label.toLowerCase()], ['asc'])}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* employment type */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputAutoCompleteV2
                    id={'employment_type'}
                    size={'normal'}
                    label="EMPLOYMENT/SCHEMA TYPE"
                    error={errors.employment_type?.txt}
                    initialValue={fields.employment_type}
                    opts={employmentType}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                  />
                </Grid>
                {/* linkedin */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'linkedin'}
                    size={'normal'}
                    type="url"
                    label="LINKEDIN PROFILE - INSERT LINK"
                    error={errors.linkedin?.txt}
                    initialValue={fields.linkedin}
                    onChangeInput={handleOnChangeInputSelect}
                    isDisabled={editing}
                    styleControl={{ height: '100%', justifyContent: 'space-between' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} lg={6}>
                  <Grid item container xs={12} spacing={2}>
                    {/* portfolio link */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'portfolio_link'}
                        size={'normal'}
                        type={'url'}
                        label="PORTFOLIO - INSERT LINK"
                        error={errors.portfolio_link?.txt}
                        initialValue={fields.portfolio_link}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                      />
                    </Grid>
                    {/* github profile */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'github_profile'}
                        size={'normal'}
                        type="url"
                        label="GITHUB PROFILE - INSERT LINK"
                        error={errors.github_profile?.txt}
                        initialValue={fields.github_profile}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                      />
                    </Grid>
                    {/* candidate_result_link */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'candidate_result_link'}
                        size={'normal'}
                        type="url"
                        label="CANDIDATE RESULT - INSERT LINK"
                        error={errors.candidate_result_link?.txt}
                        initialValue={fields.candidate_result_link}
                        onChangeInput={handleOnChangeInputSelect}
                        isDisabled={editing}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                      />
                    </Grid>
                    {/* candidate_code_score */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'candidate_code_score'}
                        size={'normal'}
                        type={editing ? 'text' : 'number'}
                        label="CANDIDATE CODE TEST SCORE"
                        error={errors.candidate_code_score?.txt}
                        initialValue={
                          editing ? `${fields.candidate_code_score}%` : setNumberFormatted(fields.candidate_code_score)
                        }
                        onChangeInput={(e) => (editing ? '' : handleOnChangeInputSelect(e))}
                        isDisabled={editing}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                        onlyNumbers={!editing}
                        onWheel={(e) => {
                          // Prevent the input value change
                          e.target.blur();
                          e.stopPropagation();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
                  <Grid item container xs={12} spacing={2} alignItems={'flex-start'}>
                    {/* availability to interview */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'availability_interview'}
                        size={'normal'}
                        label="AVAILABILITY TO INTERVIEW"
                        error={errors.availability_interview?.txt}
                        initialValue={fields.availability_interview}
                        onChangeInput={handleOnChangeInputSelect}
                        styleInput={{ mr: 1, minHeight: '152px' }}
                        styleControl={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}
                        multiline
                        rowsMultiline={5}
                        isDisabled={editing}
                      />
                    </Grid>
                    {/* info */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'info_additional'}
                        size={'normal'}
                        label="INFORMATION"
                        error={errors.info_additional?.txt}
                        initialValue={fields.info_additional}
                        onChangeInput={handleOnChangeInputSelect}
                        styleInput={{
                          mr: 1,
                          minHeight: '152px',
                        }}
                        styleControl={{
                          display: 'flex',
                          justifyContent: !interviewInputError ? 'flex-start' : 'space-between',
                          height: '100%',
                          marginTop: '0!important',
                        }}
                        styleLabel={{ marginTop: '0!important' }}
                        multiline
                        rowsMultiline={5}
                        isDisabled={editing}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* save button form */}

            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
              {editing && !showEditBottomBtn && (
                <Button
                  variant="contained"
                  sx={{ mt: '8px' }}
                  color="error"
                  startIcon={<SvgIcon style={{ color: '#fff' }} component={EditIcon} inheritViewBox />}
                  onClick={() => {
                    setEditMode(true);
                    setEditing(false);
                  }}
                >
                  edit candidate
                </Button>
              )}
            </Grid>
            {/* {!cvFormIsExpanded && ( */}
            <Grid item xs={12} sm={9} sx={{ margin: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  marginTop: 1,
                  '@media(max-width:600px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'nexxusGray.main',
                    marginRight: 2,
                  }}
                >
                  DON&apos;T FORGET TO SAVE YOUR CHANGES
                </Typography>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<CheckCircleIcon />}
                  disabled={(CandidateId !== 'create' && editing) || submitting}
                >
                  {submitting ? <CircularProgressMUI /> : 'Save Candidate'}
                </Button>
                <Typography
                  sx={{
                    fontSize: '9px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'nexxusGray.main',
                    mx: 2,
                  }}
                >
                  OR
                </Typography>
                <Button variant="action" color="warning" disabled={editing || submitting} onClick={handleDiscardChanges}>
                  <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    DISCARD CHANGES
                  </Typography>
                </Button>
              </Box>
            </Grid>
            {/* )} */}
          </Grid>

          <AccordionWrapper
            open={cvFormIsExpanded}
            handleOnChange={handleOnChangeAccordion}
            accordionSummary={"More candidate's info"}
          >
            <CandidateFormAutofilled candidateFields={candidateFields} editing={editing} skillsOptions={skillsOptions} />
          </AccordionWrapper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateForm);
