import { joiResolver } from '@hookform/resolvers/joi';
import {
  CircularProgress,
  useTheme,
  Typography,
  FormControl,
  Grid,
  OutlinedInput,
  FormLabel,
  Button,
  Link,
  FormHelperText,
  Stack,
  Box,
} from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useRef, useState } from 'react';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import SvgButton from 'components/Candidates/InterviewCandidateTab/Elements/SvgButton';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import LinkIcon from '@mui/icons-material/Link';
import useSignedS3Url from '../../../hooks/useSignedS3Url';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';
import ModalPdfViewer from 'components/Generic/Modal/ModalPdfViewer';

const AdditionalCertificates = ({ isDisabled, setHasChanged }) => {
  const [certificates, setCertificates] = useState([]);
  const [certificate, setCertificate] = useState({});
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState('');
  const [resetFileField, setResetFileField] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileViewer, setFileViewer] = useState('');

  const theme = useTheme();
  let formRef = useRef();
  let { CandidateId } = useParams();
  const { handleFileUpload, getSignedDownloadUrl } = useSignedS3Url();
  const schema = Joi.object({
    title_certificate: Joi.string().required().messages({
      'string.empty': 'Field required',
      'any.invalid': 'Field required',
    }),
    link_certificate: Joi.alternatives().conditional('file_certificate', {
      is: Joi.string().trim().invalid('', ' '),
      then: Joi.string().optional().allow(''),
      otherwise: Joi.string().uri().trim().required().messages({
        'string.empty': 'Field Link or File is required',
        'any.invalid': 'Field required',
        'string.uri': 'Field must be a valid URL',
      }),
    }),
    file_certificate: Joi.alternatives().conditional('link_certificate', {
      is: Joi.string().trim().invalid('', ' '),
      then: Joi.string().optional().allow(''),
      otherwise: Joi.string().required().messages({
        'string.empty': 'Field Link or File is required',
        'any.invalid': 'Field required',
        'string.uri': 'Field must be a valid URL',
      }),
    }),
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      title_certificate: '',
      link_certificate: '',
      file_certificate: '',
    },
  });

  useEffect(() => {
    (async () => {
      const {
        data: { certificates: certificatesDB },
      } = await candidateService.getCandidateAttachments(CandidateId);
      if (certificatesDB) setCertificates(certificatesDB);
    })();
  }, []); // eslint-disable-line

  const onSubmit = () => {
    const updateAttachmentToCandidate = async () => {
      const payload = {
        id: 'certificate',
        dest: 'candidate-attachments',
        title: getValues('title_certificate'),
        link: getValues('link_certificate'),
      };
      if (files.length > 0) {
        const s3Upload = await handleFileUpload({
          file: files[0],
          contentType: 'application/pdf',
          path: UPLOAD_LOCATIONS.CANDIDATE_ATTACHMENTS,
        });

        if (s3Upload) {
          payload.url = s3Upload.object_url;
        }
      } else {
        payload.url = '';
      }

      const { data } = await candidateService.uploadCandidateSignedFile(CandidateId, payload);
      await candidateService.updateCandidateActivity(CandidateId);
      return data;
    };

    (async () => {
      try {
        if (!saving) setSaving(true);
        const data = await updateAttachmentToCandidate();
        toast.success('New certificate saved successfully!');
        reset();
        setSaving(false);
        setResetFileField(true);
        setFiles('');
        setCertificates(data.meta_uploads.certificates);
        setHasChanged(false);
      } catch (error) {
        setSaving(false);
        let err = '';
        if (typeof error?.response?.data?.message === 'string') {
          err = error.response.data.message;
          return toast.error(err);
        }
        const objError = JSON.parse(error.response.data.message);
        err = objError.message;
        return toast.error(err);
      }
    })();
  };

  const validateErrorExists = (id) => {
    return !!errors && errors[id] && errors[id].message !== '';
  };

  const handleFileChange = ({ target }) => {
    setResetFileField(false);
    setFiles(target.files);
    setValue('file_certificate', target.files[0].name);
    trigger('link_certificate');
    trigger('file_certificate');
    handleInputChange();
  };

  const handleInputChange = () => {
    setHasChanged(true);
  };

  const columns = [
    {
      field: 'title',
      headerName: 'TITLE',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Actions',
      editable: false,
      sortable: false,
      width: 140,
      renderCell: ({ row }) => {
        return (
          <Stack sx={{ width: '100%' }} direction={'row'} spacing={1.2} justifyContent={'flex-end'} alignItems={'center'}>
            <Box sx={{ width: '30px' }}>
              {row.url !== '' && (
                <SvgButton
                  fontSize="30px"
                  color="nexxusBlack"
                  iconName="pdf-icon"
                  label=""
                  onClick={async () => {
                    const signedUrl = await getSignedDownloadUrl(row.url);
                    setFileViewer(signedUrl);
                  }}
                  isDisabled={isDisabled}
                  sxButton={{
                    padding: '8px 0',
                  }}
                  sxSvgItem={{ padding: 0, marginLeft: '2px' }}
                />
              )}
            </Box>
            <Box sx={{ width: '30px' }}>
              {row.link !== '' && (
                <Link
                  component="button"
                  type="button"
                  disabled={isDisabled}
                  sx={{
                    color: '#4d4d4d',
                    maxWidth: '28px',
                    '&[disabled]': {
                      opacity: '.2',
                      cursor: 'default',
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    },
                  }}
                  onClick={() => window.open(row.link, '_blank')}
                >
                  <LinkIcon sx={{ fontSize: '30px', top: '4px', position: 'relative' }} />
                </Link>
              )}
            </Box>
            <Box sx={{ width: '30px' }}>
              <SvgButton
                LinkComponent="a"
                disableRipple
                target="_blank"
                isDisabled={isDisabled}
                href={row.url}
                fontSize="28px"
                iconName="remove-icon"
                label=""
                onClick={() => {
                  setCertificate(row);
                  setOpenDeleteModal(true);
                }}
                sxButton={{
                  padding: '8px 0',
                }}
              />
            </Box>
          </Stack>
        );
      },
    },
  ];

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpenDeleteModal(false);
  };

  const onAcceptDelete = async () => {
    const newAt = [...certificates].filter((at) => at !== certificate);
    Promise.all([
      await candidateService.updateCandidateAttachment(CandidateId, { type: 'certificates', attachments: newAt }),
      await candidateService.updateCandidateActivity(CandidateId),
      setCertificates(newAt),
    ]);
  };

  const checkDisabled = () => {
    if (isDisabled === false) return certificates.length === 5;
    return true;
  };

  return (
    <Grid container spacing={2} component="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <ModalPdfViewer fileViewer={fileViewer} onClose={() => setFileViewer('')} />
      <ModalFeedback
        variant="warning"
        headerText="Delete Test Result"
        titleText="Are you sure you want to delete this test result?"
        open={openDeleteModal}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptDelete}
      />
      <Grid item xs={12}>
        <FormControl fullWidth sx={{ textAlign: 'left' }} disabled={checkDisabled()} variant="outlined">
          <FormLabel
            htmlFor={'title_certificate'}
            sx={{ marginTop: 'auto' }}
            error={validateErrorExists('title_certificate')}
          >
            TITLE CERTIFICATE
          </FormLabel>
          <Controller
            name={'title_certificate'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                disabled={checkDisabled()}
                error={!!errors?.title_certificate?.message || false}
                onChange={(e) => {
                  onChange(e);
                  handleInputChange();
                }}
                value={value}
              />
            )}
          />
          {!!errors && errors.title_certificate?.message !== '' && (
            <FormHelperText error id={'title_certificate'}>
              {errors.title_certificate?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth sx={{ textAlign: 'left' }} disabled={checkDisabled()}>
          <FormLabel error={validateErrorExists('link_certificate')} htmlFor={'link_certificate'} sx={{ marginTop: 'auto' }}>
            LINK CERTIFICATE
          </FormLabel>
          <Controller
            name={'link_certificate'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <OutlinedInput
                disabled={checkDisabled()}
                error={!!errors?.link_certificate?.message || false}
                onChange={(e) => {
                  onChange(e);
                  trigger('link_certificate');
                  trigger('file_certificate');
                  handleInputChange();
                }}
                value={value}
              />
            )}
          />
          {!!errors && errors.link_certificate?.message !== '' && (
            <FormHelperText error id={'link_certificate'}>
              {errors.link_certificate?.message}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth sx={{ textAlign: 'left' }} disabled={checkDisabled()}>
          <InputFileWithText
            accept={[
              'application/pdf',
            ]}
            id={'uploadFile'}
            label={'File (pdf)'}
            isDisabled={checkDisabled()}
            onFileChange={handleFileChange}
            error={errors.file_certificate?.message}
            reset={resetFileField}
          ></InputFileWithText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%', mt: '22px', [theme.breakpoints.down('md')]: { mt: 0 } }}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            startIcon={
              saving ? (
                <CircularProgress style={{ color: 'white', marginRight: 16 }} size="0.8rem" thickness={8} />
              ) : (
                <CheckCircleIcon />
              )
            }
            sx={{ width: '100%' }}
            disabled={checkDisabled()}
          >
            {saving ? 'Saving' : 'Save'}
          </Button>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
          CANDIDATE CERTIFICATES
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <DataGrid
          sx={{ mt: 1, px: 1 }}
          headerHeight={50}
          rows={certificates || []}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          disableColumnMenu
          pageSize={5}
          pagination
          getRowId={(row) => row.title + row.url}
          hideFooterPagination
          hideFooterSelectedRowCount
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalCertificates;
